<script>
export default {
	props: ['showModal', 'isOpen'],
};
</script>

<template>
	<!-- Header links -->
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
	>
		<router-link
			to="/diagnostyka-i-leczenie"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-600 dark:hover:text-green-300  sm:mx-4 mb-2 sm:py-2 "
			aria-label="Diagnostyka i leczenie"
			>Diagnostyka i leczenie</router-link
		>
    <router-link
        to="/zabiegi"
        class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-600 dark:hover:text-green-300  sm:mx-4 mb-2 sm:py-2 "
        aria-label="Zabiegi"
    >Zabiegi</router-link
    >
		<router-link
			to="/o-mnie"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-600 dark:hover:text-green-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="O mnie"
			>O mnie</router-link
		>
		<router-link
			to="/kontakt"
			class="font-general-medium block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-green-600 dark:hover:text-green-300  sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
			aria-label="Kontakt"
			>Kontakt</router-link
		>
		<div
			class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark"
		>

		</div>
	</div>
</template>

<style lang="scss" scoped></style>
