<script>
import feather from 'feather-icons';
import FooterCopyright from './FooterCopyright.vue';
export default {
	components: { FooterCopyright },
	data() {
		return {
      envire : '',
		};
	},
	mounted() {
		feather.replace();
    if(window.location.href.indexOf("localhost") < 0) {
      this.envire = 'http://c.statcounter.com/11008584/0/38203880/1/';
    }
     console.log("mode is " + this.envire)
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div id="phoneno">
		<div
			class="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark"
		>
			<!-- Footer social links -->
			<div
				class="flex flex-col justify-center items-center mb-12 sm:mb-20"
			>
				<p
					class="font-general-semibold text-3xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-5"
				>
					Umów wizytę
				</p>

        <div class="flex flex-col justify-center sm:block pt-4">

          <p
              class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left text-ternary-dark dark:text-primary-light "
          >
            Zapisy pacjentów pod numerami:<br>
            <a :href="`tel:606379728`" class="font-general-bold hover:text-green-500" >606 379 728</a><br> <a :href="`tel:694004063`" class="font-general-bold hover:text-green-500">694 004 063</a><br>
            od poniedziałku  do piątku w godz. 10.00-18.00, w soboty 10.00-14.00
          </p>
          <p
              class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-green-600"
          >
            Przyjęcia od wtorku do soboty
            po uzgodnieniu telefonicznym
            dnia i godziny wizyty.
          </p>
        </div>

			</div>

			<!-- Footer copyright -->
			<FooterCopyright />
      <div class="hide">
          <div id="statcounter_image" style="display:inline;">
              <a>
                  <img :src="this.envire" style="border:none;" />
              </a>
          </div>
      </div>
		</div>
	</div>
</template>

<style scoped></style>
