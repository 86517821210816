<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
  methods: {
    scrollToBottom() {
      let element = document.getElementById("phoneno");
      element.scrollIntoView({behavior: "smooth", block: "end"});
    }
  }
};
</script>

<template>
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
		<!-- Banner left contents -->
		<div class="w-full md:w-1/3 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
			>
        Marcin Ostrowski
			</h1>
      <h5
          class="font-general-semibold text-1xl md:text-1xl xl:text-2xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
      >
        Lekarz ortopeda
      </h5>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-gray-400"
			>
        Prywatny Gabinet Ortopedyczny<br>
        Pl. Kilińskiego 4/5<br>
        05-300 Mińsk Mazowiecki
      </p>
      <div class="flex justify-center sm:block">
        <button @click="scrollToBottom"
            class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg border border-green-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-green-50 focus:ring-1 focus:ring-green-900 hover:bg-green-500 text-gray-500 hover:text-white duration-500"
            aria-label="booking"
        >
          <i
              data-feather="smartphone"
              class="ml-0 sm:ml-1 mr-2 sm:mr-3 w-5 sm:w-6 duration-100"
          ></i>
          <span class="text-sm sm:text-lg font-general-medium duration-100">
            Umów wizytę
          </span>
        </button>
      </div>
		</div>

		<!-- Banner right illustration -->
		<div class="w-full md:w-2/3 text-right float-right">
			<img
				v-if="theme === 'light'"
				src="@/assets/images/doctors.svg"
				alt="Doctors"
			/>
			<img
				v-else
				src="@/assets/images/doctors.svg"
				alt="Doctors"
			/>
		</div>
	</section>
</template>

<style scoped></style>
